import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Contact from './Contact';
import Configdata from './Defaultdata';
import './mainapp.css';
export default class Home extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        expoption: null,
        resm: null,
        url: "",
        walk: "",
        tpb: null,
        opening: [{}]
    };
 options = [
  { value: '1', label: 'IT' },
  { value: '2', label: 'Non-IT' },
  { value: '3', label: 'Others' }
 ];
    topban = [{}];
    expops = [{}];
    middleban = [{}];
    bottonban = [{}];
    walkins = [{}];
    openings = [{}];
    typehandleChange(event: any) {
        const tv = event.value;
       
        this.setState({ typeoption: tv });
    }
    exphandleChange(event: any) {
        const ev = event.value;
       
        this.setState({ expoption: ev });
    }
   

    sendtoserver(vnm: any, vph: any, vtyp: any, vexp: any, vremarks: any) {
        const localurl = this.state.url;
       
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name: vnm, phone: vph, type: vtyp, exp: vexp, rmk: vremarks })
        };
        try {
            const promiseAwait = fetch(localurl + '/receive', requestOptions);
            alert('success');

        }
        catch (e) {
            debugger;


        }
    }
    Homehitcount() {
              
        try {
          
            const promiseAwait = fetch(Configdata.srvurl + '/Hitcountinput');
          }
        catch (e) {
            debugger;


        }
    }
    handleChangecontact = () => {
        const jid = localStorage.getItem('hotjobid');
        if (jid != 'null') {
            const element = <Contact />
            ReactDOM.render(element, document.getElementById('container'));
        }
       
    };
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;
       
        this.setState({ url: appurl });
    }
    componentDidMount() {
        debugger;
        this.Homehitcount();
        this.setserverurl();
     
        this.BindAlldivs();
       
    }
    
    hotjoblefttopbarfech(d: any) {

        const divjobhotleftbbar = document.getElementById('homemiddlebnr') as HTMLElement;
        divjobhotleftbbar.innerHTML = d;
    }
    hotjobleftbuttombarfech(d: any) {

        const divjobhotleftbbar = document.getElementById('homebuttombnr') as HTMLElement;
        divjobhotleftbbar.innerHTML = d;
    }
    BindAlldivs() {
        debugger;
        var divdata = '';
              
        fetch(Configdata.srvurl + "/gethotjobs")
            .then((res) => res.json())
            .then((data) => {
               
                if (data.length > 0) {
                    data.forEach((u: any) => {

                        const vsb = u.subj;
                        const vlnk = u.pagelink;
                        const vid = u.id;
                        const vobj = { sb: vsb, lnk: vlnk, id: vid };
                       
                        if (u.setid == 1 && this.topban.length == 1) {
                           
                            this.topban.push(vobj);
                            const p = '<p>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</p>'

                           
                            this.settingclients(vlnk, p, vsb, vid);
                           
                          
                        }
                        else if (u.setid == 5 && this.middleban.length == 1) {

                            this.middleban.push(vobj);
                            const p = '<p>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</p>'
                            
                            this.hotjoblefttopbarfech(p);

                        }

                        else if (u.setid == 6 && this.bottonban.length == 1) {

                            this.bottonban.push(vobj);
                            const p = '<p>' + '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + vsb + '</a>' + '</p>'

                            divdata = divdata + p;


                            this.hotjobleftbuttombarfech(divdata);

                        }
                        else if (u.setid == 7 && this.walkins.length > 0) {

                            this.walkins.push(vobj);
                           

                        }
                        else if (u.setid == 8 && this.openings.length > 0) {

                            this.openings.push(vobj);
                            

                        }

                    })
                    this.Bindhomewalkindiv(this.walkins);
                    this.Bindhomeopeningsdiv(this.openings);
                    this.setState({ opening: this.openings });

                }
               


            }
            );

      

    }
   
    settingclients(url: any, subl: any,sub: any, id: any) {
        const txt = '<a href="#">GOSPETSUP OPENINGS</a>';
        const divtopadd = document.getElementById('topadd') as HTMLElement;
        divtopadd.innerHTML = "";

        if (url == null || url == undefined || url == '') {
           
            localStorage.setItem("hotjobid", id);
           
            divtopadd.innerHTML =  sub ;
        }
        else {
           
            localStorage.setItem("hotjobid", 'null');
            divtopadd.innerHTML = subl;
        }
            

    }
   
    Bindhomewalkindiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }
            


        })
        this.setState({ walk: divdata });
        this.walkinfechadd(divdata);

    }
    Bindhomeopeningsdiv(data: any) {
        debugger;
        var divdata = '';
        data.forEach((u: any) => {
            if (u.sb != undefined) {
                const sb = u.sb;
                const lnk = u.lnk;

                const p = '<p>' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;
            }



        })
        this.setState({ opening: divdata });
        this.openingsfechadd(divdata);

    }
   
   
    Binhotjobdiv() {
        debugger;

        const lnames = [
            { value: '0', label: 'Other' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getwalkins")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: '' };



                    us.label = u.sname.toString();

                    us.value = u.id.toString();
                    lnames.push(us);


                })
                this.setState({ Snames: lnames });

            }
            );
    }
   
    insertresume() {
       
      
       
    }
    handleClick() {
    
                  
    } 
    walkinfechadd(d: any) {
     
        const divjobwalkinss = document.getElementById('jobwalkins') as HTMLElement;
        divjobwalkinss.innerHTML = d;
    }
    openingsfechadd(d: any) {

        const divjobwalkinss = document.getElementById('jobopenings') as HTMLElement;
        divjobwalkinss.innerHTML = d;
    }
 render() {
for (let i = 1; i <= 40; i++) {
  var opt = {value: i, label: i};
this.expops.push(opt);
}
    
     return (

<form>
          <div className="maindiv">
                 <div className="top-dibv-add">
              
                     <div id="topadd" onClick={this.handleChangecontact} className="top-add">
                         &nbsp;
                     </div>
                 
                 
                 </div>
                <div style={{ height: '30em', overflow: 'scroll' }} className="home-table">
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">

                         <tr>
                            

                             <td style={{ width: '100%', borderStyle: 'outset' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv" id="jobwalkins" >
                                     </div>
                                 </div>
                             </td>
                         </tr>

                         

                     </table>
                     <div className="top-dibv-add">

                         <div id="homemiddlebnr" onClick={this.handleChangecontact} className="top-add">
                         &nbsp;
                     </div>
                    </div>
                     <table style={{ width: '100%', height: 'auto', border: 'none' }} className="home-table">
                        
                         <tr>
                            
                           
                             <td style={{ width: '100%', border: 'none' }}>
                                 <div className="middlediv">
                                     <div className="containerlinkdiv" id="jobopenings" >
                                     </div>
                                 </div>
                             </td>
                         </tr>

                         </table>
                     <div className="top-dibv-add">

                             <div id="homebuttombnr" onClick={this.handleChangecontact} className="top-add">
                             &nbsp;
                             </div>
                         
                        </div>
              
                  
              </div>
            
    

        </div>



   </form>

      );
}
}
//export default Home;
