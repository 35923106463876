import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Configdata from './Defaultdata';

import './table.css';
export default class Jobsearch extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        catoption: null,
        snameoptionval: null,
        snameoptionlbl: null,
        expoption: null,
        resm: null,
        viewtype: null,
        scrname: "",
        scrcode: "",
        scrremarks: "",
        scrcapital: null,
        settingoption: null,
        Onlinetest: [{ id: '0', subj: "Test", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }],
        Scripts: [{ id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }],
        Cats: [{ value: '0', label: "OTHER" }],
        Snames: [{ value: '0', label: "OTHER" }],
        Scrptstatus: [{ id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }],
        Hotstatus: [{ id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }]
    };
    usrps = [
        { id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }

    ];
    scrstatus = [
        { id: '0', sname: "Statebank of India", min: '', mindate: '', max: '', maxdate: '',year:'' }

    ];
 options = [
  { value: '1', label: 'IT' },
     { value: '2', label: 'Non IT' },
     { value: '3', label: 'Others' },
     { value: '4', label: 'Top Add' },
     { value: '5', label: 'Middle Add' },
     { value: '6', label: 'Buttom Add' }
   
 ];
    category = [
        { value: '0', label: 'Other' }
       
    ];
    settings = [
        { value: '0', label: 'Other' },
        { value: '1', label: 'Topbanner' },
        { value: '2', label: 'Jobhome left banner' },
        { value: '3', label: 'General' },
        { value: '4', label: 'Hotjob' },
        { value: '5', label: 'Home left Top banner' },
        { value: '6', label: 'Home left Buttom banner' },
        { value: '7', label: 'Walkins' },
        { value: '8', label: 'Openings' },

    ];
    cities = [
        { value: 0, label: 'Others' },
        { value: 1, label: 'New Dehli' },
        { value: 2, label: 'Gurgaon' },
        { value: 3, label: 'Novaluea' },
        { value: 4, label: 'Mumbai' },

        { value: 5, label: 'Kolkata' },
        { value: 6, label: 'Howrah' },
        { value: 7, label: 'Chennai' },
        { value: 8, label: 'Hyderabad' },
        { value: 9, label: 'Bengaluru' },
        { value: 10, label: 'Ahmedabad' },
        { value: 11, label: 'Surat' },
        { value: 12, label: 'Amritsar' },
        { value: 13, label: 'Bhopal' },
        { value: 14, label: 'Bhubaneswar' },
        { value: 15, label: 'Cuttack' },
        { value: 16, label: 'Chandigarh' },
        { value: 17, label: 'Farvalueabad' },
        { value: 18, label: 'Ghaziabad' },
        { value: 19, label: 'Jamshedpur' },
        { value: 20, label: 'Jaipur' },
        { value: 21, label: 'Kochi' },
        { value: 22, label: 'Lucknow' },
        { value: 23, label: 'Nagpur' },
        { value: 24, label: 'Patna' },
        { value: 25, label: 'Raipur' },
        { value: 26, label: 'Visakhapatnam' },
        { value: 27, label: 'Agra' },
        { value: 28, label: 'Ajmer' },
        { value: 29, label: 'Kanpur' },
        { value: 30, label: 'Mysore' },
        { value: 31, label: 'Srinagar' },
        { value: 32, label: 'Pune' },
    ];
    arrnames = [
        { value: '0', label: 'Other' }

    ];
    expops = [{}];
    typehandleChange(event: any) {
        const tv = event.value;
       
        this.setState({ typeoption: tv });
    }
    settinghandleChange(event: any) {
        const tv = event.value;

        this.setState({ settingoption: tv });
    }
    categoryhandleChange(event: any) {
        const tv = event.value;

        this.setState({ catoption: tv });
    }
    SnamehandleChange(event: any) {
        const tv = event.value;
        const tl = event.label;

        this.setState({ snameoptionval: tv });
        this.setState({ snameoptionlbl: tl });
    }
    //sendtoserver(vnm: any, vcode: any, vtyp: any, vremarks: any, Capitals: any,ctype: any) {
    sendtoserver(vnm: any, vid: any, sub: any, links: any, wdates: any, edate: any, tid: any, cid: any) {
            debugger;
        const id = localStorage.getItem("id");
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sname: vnm, sid: vid, subj: sub, plinkage: links, walkindates: wdates, expdate: edate, typeid: tid, cityid: cid, uid: id })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/walkinsinput', requestOptions);
            alert('Saved successfully');
          
            this.Viewwalkins();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    sendtoserveronlinetest(sub: any, links: any, tdates: any, edate: any, tid: any) {
        debugger;
        const id = localStorage.getItem("id");
        const catid = '0';
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ subj: sub, plinkage: links, testndates: tdates, expdate: edate, testtypyid: tid, testcatid: catid, uid: id })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/onlinetestsinput', requestOptions);
            alert('Saved successfully');

            this.Viewonlinetest();
          
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    updatewalkinstatus(vid: any, sts: any) {
        debugger;
        const id = localStorage.getItem("id");
        const stsid = sts == 1 ? 2 : 1;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ wid: vid, sts: stsid })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/walkinschangestatus', requestOptions);
            alert('Saved successfully');

            this.Viewwalkins();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    updateonlinetesttatus(vid: any, sts: any) {
        debugger;
        const id = localStorage.getItem("id");
        const stsid = sts == 1 ? 2 : 1;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ wid: vid, sts: stsid })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/onlinetestchangestatus', requestOptions);
            alert('Saved successfully');

            this.Viewonlinetest();
          
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    updateopeningstatus(vid: any, sts: any) {
        debugger;
        const id = localStorage.getItem("id");
        const stsid = sts == 1 ? 2 : 1;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ wid: vid, sts: stsid })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/openingschangestatus', requestOptions);
            alert('Saved successfully');

            this.Viewonlinetest();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    updatehotjobstatus(vid: any, sts: any) {
        debugger;
        const id = localStorage.getItem("id");
        const stsid = sts == 1 ? 2 : 1;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ wid: vid, sts: stsid })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/hotjobchangestatus', requestOptions);
            alert('Saved successfully');

            this.Viewhotjob();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    sendstatustoserver(vnm: any, vid: any, sub: any, links: any, wdates: any, edate: any, tid: any, cid: any) {

        const id = localStorage.getItem("id");

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sname: vnm, sid: vid, subj: sub, plinkage: links, walkindates: wdates, expdate: edate, typeid: tid, cityid: cid, uid: id })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/openingsinput', requestOptions);
            alert('Saved successfully');

            this.Viewscriptstatus();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    sendhotjobtoserver(vnm: any, vid: any, sub: any, links: any, wdates: any, edate: any, tid: any, cid: any,settingid: any) {

        const id = localStorage.getItem("id");

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sname: vnm, sid: vid, subj: sub, plinkage: links, walkindates: wdates, expdate: edate, typeid: tid, cityid: cid, uid: id, setid: settingid })
        };
        try {
            const promiseAwait = fetch(Configdata.srvurl + '/hotjobsinput', requestOptions);
            alert('Saved successfully');

            this.Viewscriptstatus();
            this.BindScriptnames();

            this.clearscript();
        }
        catch (e) {
            debugger;

            alert('error');
        }
    }
    Viewwalkins() {
       
        this.setState({ viewtype: 'scr' });
       
        debugger;
        //const element = <Userscripts />;
       // const div = document.getElementById('userscriptdiv') as HTMLElement;
       // div.innerHTML = '';
        // ReactDOM.render(element, div);
      const  lusrps = [
          { id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getwalkinssts/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '', subj: ' ', pagelink: '', rank: '', status: '', sts: '', expd: '' };

                    const vlnk = u.subj.toString();
                    /* us.subj = '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + u.subj + '</a>';*/
                    us.subj =   u.subj ;
                    us.rank = u.rankid.toString();
                    const sts = u.status.toString();
                    if(sts == '1')
                        us.status = 'of';
                    else if (sts == '2')
                        us.status = 'on';
                    else
                        us.status = 'un';
                    us.pagelink = u.pagelink;
                    us.id = u.id.toString();
                    us.sts = u.id + '-' + sts;
                    us.expd = u.expdate.toString();
                    lusrps.push(us);
                    

                })
                this.setState({ Scripts: lusrps });
               // setuscripts(usrps);
            }
            );
    }
    Viewopenings() {

        this.setState({ viewtype: 'scr' });

        debugger;
        //const element = <Userscripts />;
        // const div = document.getElementById('userscriptdiv') as HTMLElement;
        // div.innerHTML = '';
        // ReactDOM.render(element, div);
        const lusrps = [
            { id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }

        ];
        const id = localStorage.getItem("id");
      
        fetch(Configdata.srvurl + "/getopeningssts/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '', subj: ' ', pagelink: '', rank: '', status: '', sts: '', expd: '' };

                    const vlnk = u.subj.toString();
                    /* us.subj = '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + u.subj + '</a>';*/
                    us.subj = u.subj;
                    us.rank = u.rankid.toString();
                    const sts = u.status.toString();
                    if (sts == '1')
                        us.status = 'of';
                    else if (sts == '2')
                        us.status = 'on';
                    else
                        us.status = 'un';
                    us.pagelink = u.pagelink;
                    us.id = u.id.toString();
                    us.sts = u.id + '-' + sts;
                    us.expd = u.expdate.toString();
                    lusrps.push(us);


                })
                this.setState({ Scrptstatus: lusrps });
                // setuscripts(usrps);
            }
            );
    }
    Viewhotjob() {

        this.setState({ viewtype: 'scr' });

        debugger;
        //const element = <Userscripts />;
        // const div = document.getElementById('userscriptdiv') as HTMLElement;
        // div.innerHTML = '';
        // ReactDOM.render(element, div);
        const lusrps = [
            { id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }

        ];
        const id = localStorage.getItem("id");

        fetch(Configdata.srvurl + "/gethotjobsts/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '', subj: ' ', pagelink: '', rank: '', status: '', sts: '', expd: '' };

                    const vlnk = u.subj.toString();
                    /* us.subj = '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + u.subj + '</a>';*/
                    us.subj = u.subj;
                    us.rank = u.rankid.toString();
                    const sts = u.status.toString();
                    if (sts == '1')
                        us.status = 'of';
                    else if (sts == '2')
                        us.status = 'on';
                    else
                        us.status = 'un';
                    us.pagelink = u.pagelink;
                    us.id = u.id.toString();
                    us.sts = u.id + '-' + sts;
                    us.expd = u.expdate.toString();
                    lusrps.push(us);


                })
                this.setState({ Hotstatus: lusrps });
                // setuscripts(usrps);
            }
            );
    }
    Viewscriptstatus() {
        this.setState({ viewtype: 'sts' });
        debugger;
       
        const lusrps = [{ id: '0', sname: "Statebank of India", min: '0', mindate: '2023-01-01', max: '0', maxdate: '2023-01-01', year: '2023',oth:'heigh' }];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getscriptstatus/" + id)
            .then((res) => res.json())
            .then((data) => {
               
                data.forEach((u: any) => {
                    const us = { id: '0', sname: "Statebank of India", min: '0', mindate: '2023-01-01', max: '0', maxdate: '2023-01-01', year: '2023', oth: 'heigh' };
                    us.id = u.id;
                    us.sname = u.sname;
                    us.min = u.min;
                    us.mindate = u.mindate;
                    us.max = u.max
                    us.maxdate = u.maxdate;
                    us.year = u.yr;
                    us.oth = u.remarks;

                    lusrps.push(us);


                })
                this.setState({ Scrptstatus: lusrps });
                // setuscripts(usrps);
            }
            );
    }

    Viewonlinetest() {

        this.setState({ viewtype: 'scr' });

        debugger;
        //const element = <Userscripts />;
        // const div = document.getElementById('userscriptdiv') as HTMLElement;
        // div.innerHTML = '';
        // ReactDOM.render(element, div);
        const lusrps = [
            { id: '0', subj: "walkins", pagelink: '', rank: '1', status: '55', sts: '', expd: '' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getonlineteststs/" + id)
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { id: '', subj: ' ', pagelink: '', rank: '', status: '', sts: '', expd: '' };

                    const vlnk = u.subj.toString();
                    /* us.subj = '<a href=' + vlnk + '  ' + 'target="_blank"' + '>' + u.subj + '</a>';*/
                    us.subj = u.subj;
                    us.rank = u.rankid.toString();
                    const sts = u.status.toString();
                    if (sts == '1')
                        us.status = 'of';
                    else if (sts == '2')
                        us.status = 'on';
                    else
                        us.status = 'un';
                    us.pagelink = u.pagelink;
                    us.id = u.id.toString();
                    us.sts = u.id + '-' + sts;
                    us.expd = u.expdate.toString();
                    lusrps.push(us);


                })
                this.setState({ Onlinetest: lusrps });
                // setuscripts(usrps);
            }
            );
    }


    BindCategory() {
        debugger;
        //const element = <Userscripts />;
        // const div = document.getElementById('userscriptdiv') as HTMLElement;
        // div.innerHTML = '';
        // ReactDOM.render(element, div);

        const id = localStorage.getItem("id");
        
        fetch(Configdata.srvurl + "/Category")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: ''};


              
                    us.label = u.name.toString();
               
                    us.value = u.id.toString();
                this.category.push(us);


                })
              
                this.setState({ Cats: this.category });
               
            }
            );
    }

    BindScriptnames() {
        debugger;
       
       const lnames = [
            { value: '0', label: 'Other' }

        ];
        const id = localStorage.getItem("id");
        fetch(Configdata.srvurl + "/getemployeers")
            .then((res) => res.json())
            .then((data) => {
                data.forEach((u: any) => {
                    const us = { value: '', label: '' };



                    us.label = u.name.toString();

                    us.value = u.id.toString();
                    lnames.push(us);


                })
                this.setState({ Snames: lnames });

            }
            );
    }
    componentDidMount() {
     
        this.BindCategory();
      
        this.Checkscript();
      
        this.Viewwalkins();
        this.Viewopenings();
        this.Viewhotjob();
        this.disablealldiv();
      
        this.BindScriptnames();
     
        this.Viewscriptstatus();
     
    }
    Checkscript() {
        
        const txtcode = document.getElementById('Code') as HTMLInputElement;
        const vcode = txtcode.value.trim();
        if (vcode != null && vcode != '' && vcode != undefined) {
            this.insertscript();
            
        }
        
      
    }
    clearscript() {
        const frm = document.getElementById("insertscriptsts") as HTMLFormElement;
        frm.reset();
   
    }
    insertscript() {
        debugger;
        const vsname = this.state.snameoptionlbl;
        const vsid = this.state.snameoptionval;

        const tid = this.state.typeoption;
        const cid = this.state.catoption;

        //const txtnm = document.getElementById('sname') as HTMLInputElement;
        //const vnm = txtnm.value.trim();
        const txtsub = document.getElementById('Code') as HTMLInputElement;
        const sub = txtsub.value.trim();

        const txtlinks = document.getElementById('wplikn') as HTMLInputElement;
        const vlinks = txtlinks.value.trim();

       

       
        const txtremarks = document.getElementById('remarks') as HTMLInputElement;
        const vwdates = txtremarks.value.trim();

        const txtedate = document.getElementById('maxdate') as HTMLInputElement;
        const vedate = txtedate.value.trim();

        const txtpost = document.getElementById('scaps') as HTMLInputElement;
        const vpost = txtpost.value.trim();
        const vtyp = this.state.typeoption;
        const ctyp = this.state.catoption;
       
        if (sub == null || sub == undefined || sub == '' || vlinks == undefined || vlinks == '') {
            alert('Please Enter subject. or linkl');
            return;
        }

        this.sendtoserver(vsname, vsid, sub, vlinks, vwdates, vedate, tid,cid);
       
       
    }
    insertonlinetest() {

        debugger;
        const vsname = this.state.snameoptionlbl;
        const vsid = this.state.snameoptionval;

        const tid = this.state.typeoption;
        const cid = 0;

        const txtsub = document.getElementById('testCode') as HTMLInputElement;
        const sub = txtsub.value.trim();

        const txtlinks = document.getElementById('testlikn') as HTMLInputElement;
        const vlinks = txtlinks.value.trim();

        const txttestdate = document.getElementById('tdate') as HTMLInputElement;
        const tdate = txttestdate.value.trim();

        const txtexpdate = document.getElementById('texpdate') as HTMLInputElement;
        const expdate = txtexpdate.value.trim();

        const txtpost = document.getElementById('scaps') as HTMLInputElement;
        const vpost = txtpost.value.trim();
        const vtyp = this.state.typeoption;
        const ctyp = 0;

        if (sub == null || sub == undefined || sub == '' || vlinks == undefined || vlinks == '') {
            alert('Please Enter subject. or linkl');
            return;
        }

        this.sendtoserveronlinetest(sub, vlinks, tdate, expdate, tid);
    }
    insertscriptstatus() {
       
        const vsname = this.state.snameoptionlbl;
        const vsid = this.state.snameoptionval;

        const tid = this.state.typeoption;
        const cid = this.state.catoption;
       
        const txtmin = document.getElementById('min') as HTMLInputElement;
        const sub = txtmin.value.trim();
        const txtmax = document.getElementById('max') as HTMLInputElement;
        const vmax = txtmax.value.trim();

        const txtlink = document.getElementById('oplink') as HTMLInputElement;
        const vlinks = txtlink.value.trim();

        const txtmindate = document.getElementById('mindate') as HTMLInputElement;
        const vedate = txtmindate.value.trim();

        const txtmaxdate = document.getElementById('lastdate') as HTMLInputElement;
        const vwdates = txtmaxdate.value.trim();
        const txtyear = document.getElementById('year') as HTMLInputElement;
        const vyear = txtyear.value.trim();

        const txtremarks = document.getElementById('stsremarks') as HTMLInputElement;
        const vremarks = txtremarks.value.trim();

       
        
        if (vsid == null || vsid == undefined || vsid == '') {
            alert('Please select Name');
            return;
        }
       
       // this.sendstatustoserver(vsname, vsid, vmin, vmax,vmindate,vmaxdate,vyear,vremarks);
        this.sendstatustoserver(vsname, vsid, sub, vlinks, vwdates, vedate, tid, cid);
    }
    inserthotjobs() {

        const vsname = this.state.snameoptionlbl;
        const vsid = this.state.snameoptionval;

        const tid = this.state.typeoption;
        const cid = this.state.catoption;

        const setid = this.state.settingoption;

        const txtmin = document.getElementById('hmin') as HTMLInputElement;
        const sub = txtmin.value.trim();
        const txtmax = document.getElementById('hmax') as HTMLInputElement;
        const vmax = txtmax.value.trim();

        const txtlink = document.getElementById('hoplink') as HTMLInputElement;
        const vlinks = txtlink.value.trim();

        const txtmindate = document.getElementById('hmindate') as HTMLInputElement;
        const vedate = txtmindate.value.trim();

        const txtmaxdate = document.getElementById('hlastdate') as HTMLInputElement;
        const vwdates = txtmaxdate.value.trim();
        const txtyear = document.getElementById('hyear') as HTMLInputElement;
        const vyear = txtyear.value.trim();

        const txtremarks = document.getElementById('hstsremarks') as HTMLInputElement;
        const vremarks = txtremarks.value.trim();



        if (vsid == null || vsid == undefined || vsid == '') {
            alert('Please select Name');
            return;
        }

        // this.sendstatustoserver(vsname, vsid, vmin, vmax,vmindate,vmaxdate,vyear,vremarks);
        this.sendhotjobtoserver(vsname, vsid, sub, vlinks, vwdates, vedate, tid, cid, setid);
    }
    handleClick() {
     
        this.Checkscript();
        
                  
    } 
    handleopeningsstatusClick() {

        this.insertscriptstatus();

    } 
    handlehotjobClick() {

        this.inserthotjobs();


    } 
    handleonlinetestClick() {

        this.insertonlinetest();


    } 
   
    disablealldiv() {
        const scriptdiv = document.getElementById('scriptentryform') as HTMLElement;
        scriptdiv.style.display = 'none';
        const statusdiv = document.getElementById('statusform') as HTMLElement;
        statusdiv.style.display = 'none';
        const hscriptdiv = document.getElementById('hjobform') as HTMLElement;
        hscriptdiv.style.display = 'none';
        const statusgrid = document.getElementById('statusgrid') as HTMLElement;
        statusgrid.style.display = 'none';
        const scriptgrid = document.getElementById('scriptgrid') as HTMLElement;
        scriptgrid.style.display = 'none';

        const hscriptgrid = document.getElementById('hstatusgrid') as HTMLElement;
        hscriptgrid.style.display = 'none';


        const tscriptdiv = document.getElementById('testscriptentryform') as HTMLElement;
        tscriptdiv.style.display = 'none';

        const tscriptgrid = document.getElementById('testscriptgrid') as HTMLElement;
        tscriptgrid.style.display = 'none';
    }
    activeScriptentrty() {
        this.disablealldiv();
        const scriptdiv = document.getElementById('scriptentryform') as HTMLElement;
        scriptdiv.style.display = 'block';
      
        
    }
    activeScriptstatus() {
        this.disablealldiv();
        this.Viewwalkins();
        const scriptdiv = document.getElementById('statusform') as HTMLElement;
        scriptdiv.style.display = 'block';

    }

  
    activeScriptentrtygrid() {
        debugger;
        this.disablealldiv();
       
        const scriptgrid = document.getElementById('scriptgrid') as HTMLElement;
        scriptgrid.style.display = 'block';

    }
    activeScriptstatugrids() {
        this.disablealldiv();
        this.Viewopenings();
        const statusgrid = document.getElementById('statusgrid') as HTMLElement;
        statusgrid.style.display = 'block';
    }

    activehotjobentry() {
        debugger;
        this.disablealldiv();

        const hscriptgrid = document.getElementById('hjobform') as HTMLElement;
        hscriptgrid.style.display = 'block';

    }
    activehotjobgrids() {
        this.disablealldiv();
        this.Viewhotjob();
        const hstatusgrid = document.getElementById('hstatusgrid') as HTMLElement;
        hstatusgrid.style.display = 'block';
    }
    activeonlinetest() {
        this.disablealldiv();
        this.Viewonlinetest();
        const scriptdiv = document.getElementById('testscriptentryform') as HTMLElement;
        scriptdiv.style.display = 'block';

    }
    activetestgrids() {
        this.disablealldiv();
       // this.Viewhotjob();
        const hstatusgrid = document.getElementById('testscriptgrid') as HTMLElement;
        hstatusgrid.style.display = 'block';
    }
    changeestatus(evt: any) {
        const v = evt.currentTarget.getAttribute("data-value");
        var sv = v.split('-');
        var vid = sv[0];
        var stsid = sv[1];
        this.updatewalkinstatus(vid, stsid);
    }
    changestatusopenings(evt: any) {
        const v = evt.currentTarget.getAttribute("data-value")
        var sv = v.split('-');
        var vid = sv[0];
        var stsid = sv[1];
        this.updateopeningstatus(vid, stsid);
        
    }
    changestatushotjob(evt: any) {
        const v = evt.currentTarget.getAttribute("data-value")
        var sv = v.split('-');
        var vid = sv[0];
        var stsid = sv[1];
        this.updatehotjobstatus(vid, stsid);

    }
    changestatusonlinetest(evt: any) {
        const v = evt.currentTarget.getAttribute("data-value")
        var sv = v.split('-');
        var vid = sv[0];
        var stsid = sv[1];
        this.updateonlinetesttatus(vid, stsid);

    }
    updaterank(evt: any) {
        
}
 render() {

    
  return (
<form id="insertscriptsts">
 <div className="maindiv">
    <div className="top-dibv-scr">
                  <div>
                      <span className="border-p">
                          <button onClick={this.activeScriptentrty.bind(this)}>WALKIN</button>
                      </span>
                      <span className="border-p">
                          <button onClick={this.activeScriptstatus.bind(this)}>OPENINGS</button>
                      </span>
                      <span className="border-p">
                          <button onClick={this.activehotjobentry.bind(this)}>HOTJOBS</button>
                      </span>
                      <span className="border-p">
                          <button onClick={this.activeonlinetest.bind(this)}>TEST</button>
                      </span>
                  </div>   
              </div>
    
              <div id="walkinsentry" className="containerdiv">
                  <div id="scriptentryform" className="left-center-div">
                      <div className="containerdiv">
            <div className="innerdiv-scr">
                <div className="separate">
                    <span className="lable">
                        comps:
                    </span>
                                  <span className="lable">
                                      <Select onChange={e => this.SnamehandleChange(e)} id="wclient" options={this.state.Snames} className="textbox-name" />
                                      <input type="text" style={{ display:'none'}} id="sname" className="textbox-name-top" />
                    </span>
                          </div>
                          <div className="separatejob">
                              <span className="lable">
                                 sub:
                              </span>
                              <span className="lable">
                                  <input type="text" id="Code" className="textbox-name-top" />
                              </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Page link:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="wplikn" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                    <span className="lable">
                        Type:&nbsp;&nbsp;
                    </span>
                   <span className="lable">
                                  <Select onChange={e => this.typehandleChange(e) } id="utype" options={this.options} className="textbox-name"/>

                                  </span>
                               
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      City:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.categoryhandleChange(e)} id="ctype" options={this.cities} className="textbox-name" />

                                  </span>
                              </div>
                              <div className="separatejob">
                    <span className="lable">
                      Post:
                    </span>
                    <span className="lable">
                                  <input type="text" id="scaps" className="textbox-name-top" />
                    </span >
                   
             
                 </div>
                              <div className="separatejob">
                              <span className="lable">
                                  Walkin Dates:
                              </span>
                              <span className="lable">
                                      <input placeholder="yyyy-mm-dd,yyyy-mm-dd" type="text" id="remarks" className="textbox-name-top" />
                              </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                     Exp :
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="maxdate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separatejob">
                  
                    <span className="middle-button">
                                      <button onClick={this.handleClick.bind(this)} >Submit</button>
                                      <button onClick={this.activeScriptentrtygrid.bind(this)} >View</button>
                    </span>
                                  <span className="middle-button">
                                     
                                  </span>
                  
                </div>
                          
                         
            </div>

       

             </div>
          </div>
                  <div id="scriptgrid" className="left-div">
                      <div id="userscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>

                                      <th className="main-th">sub</th>
                                      <th className="main-th">rank</th>
                                      <th className="main-th">expdate</th>
                                      <th className="main-th">status</th>
                                    
                                      <th className="main-th">update</th>

                                  </tr>
                                  <tbody>

                                      {this.state.Scripts.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  <a style={{color:'green' }} href={s.pagelink} target="_blank" > {s.subj}  </a>
                                                 
                                              </td>
                                              <td className="main-td">
                                                  {s.rank}
                                                 
                                              </td>
                                              <td className="main-td">
                                                  {s.expd}

                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts}  onClick={e => this.changeestatus(e)} > {s.status}</button>
                                                 
                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.updaterank(e)} >Update Rank</button>

                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
            </div>
              </div>


              <div id="openingsentry" className="containerdiv">

                  <div id="statusform" className="left-center-div">
                      <div className="containerdiv">
                          <div className="innerdiv-scr">
                              <div className="separatejob">
                                  <span className="lable">
                                      comps:
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.SnamehandleChange(e)} id="utype" options={this.state.Snames} className="textbox-name" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Sub:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="min" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Page link:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="oplink" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Type:&nbsp;&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.typehandleChange(e)} id="outype" options={this.options} className="textbox-name" />

                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      City:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.categoryhandleChange(e)} id="octype" options={this.cities} className="textbox-name" />

                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Post:&nbsp;
                                  </span>
                                  <span className="lable">

                                      <input type="text" id="max" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                    Last Dates:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="lastdate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div style={{ display: 'none' }} className="separate">
                                  <span className="lable">
                                      Year:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="year" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div style={{ display: 'none' }} className="separate">
                                  <span className="lable">
                                      Oths:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="stsremarks" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                    Exp  Date:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="mindate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separatejob">

                                  <span className="middle-button">
                                      <button onClick={this.handleopeningsstatusClick.bind(this)} >Submit</button>
                                      <button onClick={this.activeScriptstatugrids.bind(this)} >View</button>
                                  </span>
                                  <span className="middle-button">

                                  </span>

                              </div>


                          </div>



                      </div>
                  </div>
                  <div id="statusgrid" className="left-div">
                      <div id="statuscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>

                                      <th className="main-th">sub</th>
                                      <th className="main-th">rank</th>
                                      <th className="main-th">expdate</th>
                                      <th className="main-th">status</th>
                                     
                                      <th className="main-th">update</th>
                                  </tr>
                                  <tbody>

                                      {this.state.Scrptstatus.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  <a style={{ color: 'green' }} href={s.pagelink} target="_blank" > {s.subj}  </a>

                                              </td>
                                              <td className="main-td">
                                                  {s.rank}

                                              </td>
                                              <td className="main-td">
                                                  {s.expd}

                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.changestatusopenings(e)} > {s.status}</button>

                                              </td>
                                             
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.updaterank(e)} >Update Rank</button>

                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
                  </div>
                  
                  
              </div>



              <div id="hjobentry" className="containerdiv">

                  <div id="hjobform" className="left-center-div">
                      <div className="containerdiv">
                          <div className="innerdiv-scr">
                              <div className="separatejob">
                                  <span className="lable">
                                      comps:
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.SnamehandleChange(e)} id="utype" options={this.state.Snames} className="textbox-name" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                    Hot Sub:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="hmin" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Page link:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="hoplink" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Type:&nbsp;&nbsp;
                                  </span>
                                  <span className="lable">
                                    
                                      <Select onChange={e => this.typehandleChange(e)} id="houtype" options={this.options} className="textbox-name" />
                                     
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Set:&nbsp;&nbsp;
                                  </span>
                                  <span className="lable">
                                     
                                      <Select onChange={e => this.settinghandleChange(e)} id="hsetting" options={this.settings} className="textbox-name" />
                                 
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      City:&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.categoryhandleChange(e)} id="hoctype" options={this.cities} className="textbox-name" />

                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Post:&nbsp;
                                  </span>
                                  <span className="lable">

                                      <input type="text" id="hmax" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Last Dates:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="hlastdate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div style={{ display: 'none' }} className="separate">
                                  <span className="lable">
                                      Year:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="hyear" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div style={{ display: 'none' }} className="separate">
                                  <span className="lable">
                                      Oths:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="hstsremarks" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Exp  Date:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="hmindate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separatejob">

                                  <span className="middle-button">
                                      <button onClick={this.handlehotjobClick.bind(this)} >Submit</button>
                                      <button onClick={this.activehotjobgrids.bind(this)} >View</button>
                                  </span>
                                  <span className="middle-button">

                                  </span>

                              </div>


                          </div>



                      </div>
                  </div>
                  <div id="hstatusgrid" className="left-div">
                      <div id="hstatuscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>


                                      <th className="main-th">sub</th>
                                      <th className="main-th">rank</th>
                                      <th className="main-th">expdate</th>
                                      <th className="main-th">status</th>
                                     
                                      <th className="main-th">update</th>
                                  </tr>
                                  <tbody>

                                      {this.state.Hotstatus.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  <a style={{ color: 'green' }} href={s.pagelink} target="_blank" > {s.subj}  </a>

                                              </td>
                                              <td className="main-td">
                                                  {s.rank}

                                              </td>
                                              <td className="main-td">
                                                  {s.expd}

                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.changestatushotjob(e)} > {s.status}</button>

                                              </td>
                                              
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.updaterank(e)} >Update Rank</button>

                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
                  </div>


              </div>




              <div id="onlinetestentry" className="containerdiv">
                  <div id="testscriptentryform" className="left-center-div">
                      <div className="containerdiv">
                          <div className="innerdiv-scr">
                            
                              <div className="separatejob">
                                  <span className="lable">
                                      sub:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="testCode" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Page link:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="testlikn" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Type:&nbsp;&nbsp;
                                  </span>
                                  <span className="lable">
                                      <Select onChange={e => this.typehandleChange(e)} id="testtype" options={this.options} className="textbox-name" />

                                  </span>

                              </div>
                              
                              <div className="separatejob">
                                  <span className="lable">
                                      Post:
                                  </span>
                                  <span className="lable">
                                      <input type="text" id="testscaps" className="textbox-name-top" />
                                  </span >


                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Test Dates:
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd,yyyy-mm-dd" type="text" id="tdate" className="textbox-name-top" />
                                  </span>
                              </div>
                              <div className="separatejob">
                                  <span className="lable">
                                      Exp :
                                  </span>
                                  <span className="lable">
                                      <input placeholder="yyyy-mm-dd" type="text" id="texpdate" className="textbox-name-top" />

                                  </span>
                              </div>
                              <div className="separatejob">

                                  <span className="middle-button">
                                      <button onClick={this.handleonlinetestClick.bind(this)} >Submit</button>
                                      <button onClick={this.activetestgrids.bind(this)} >View</button>
                                  </span>
                                  <span className="middle-button">

                                  </span>

                              </div>


                          </div>



                      </div>
                  </div>
                  <div id="testscriptgrid" className="left-div">
                      <div id="testuserscriptdiv" className="containtdiv-tbl">
                          <div>

                              <table className="main-table">
                                  <tr>

                                      <th className="main-th">sub</th>
                                      <th className="main-th">rank</th>
                                      <th className="main-th">expdate</th>
                                      <th className="main-th">status</th>

                                      <th className="main-th">update</th>

                                  </tr>
                                  <tbody>

                                      {this.state.Onlinetest.map((s) => (
                                          <tr>
                                              <td className="main-td">
                                                  <a style={{ color: 'green' }} href={s.pagelink} target="_blank" > {s.subj}  </a>

                                              </td>
                                              <td className="main-td">
                                                  {s.rank}

                                              </td>
                                              <td className="main-td">
                                                  {s.expd}

                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.changestatusonlinetest(e)} > {s.status}</button>

                                              </td>
                                              <td className="main-td">
                                                  <button data-value={s.sts} onClick={e => this.updaterank(e)} >Update Rank</button>

                                              </td>
                                          </tr>
                                      ))}


                                  </tbody>
                              </table>

                          </div>

                      </div>
                  </div>
              </div>

</div>
</form>

      );
}
}
//export default Home;
