import React, { Component, useState, useEffect } from 'react';
import Select from 'react-select';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { debug } from 'util';
import Check from './Check';
import Contact from './Contact';
import Configdata from './Defaultdata';
import './mainapp.css';
export default class Jobhome extends Component {
    state = {
        name: "World",
        type: "1",
        Phone: null,
        msg: "",
        typeoption: null,
        expoption: null,
        resm: null,
        url: "",
        walk: "",
        catoption: null,
        windowHeight: null,
        windowWidth: null
    };
 options = [
  { value: '1', label: 'IT' },
  { value: '2', label: 'Non-IT' },
  { value: '3', label: 'Others' }
 ];
    cities = [
        { value: 0, label: 'Others' },
        { value: 1, label: 'New Dehli' },
        { value: 2, label: 'Gurgaon' },
        { value: 3, label: 'Novaluea' },
        { value: 4, label: 'Mumbai' },

        { value: 5, label: 'Kolkata' },
        { value: 6, label: 'Howrah' },
        { value: 7, label: 'Chennai' },
        { value: 8, label: 'Hyderabad' },
        { value: 9, label: 'Bengaluru' },
        { value: 10, label: 'Ahmedabad' },
        { value: 11, label: 'Surat' },
        { value: 12, label: 'Amritsar' },
        { value: 13, label: 'Bhopal' },
        { value: 14, label: 'Bhubaneswar' },
        { value: 15, label: 'Cuttack' },
        { value: 16, label: 'Chandigarh' },
        { value: 17, label: 'Farvalueabad' },
        { value: 18, label: 'Ghaziabad' },
        { value: 19, label: 'Jamshedpur' },
        { value: 20, label: 'Jaipur' },
        { value: 21, label: 'Kochi' },
        { value: 22, label: 'Lucknow' },
        { value: 23, label: 'Nagpur' },
        { value: 24, label: 'Patna' },
        { value: 25, label: 'Raipur' },
        { value: 26, label: 'Visakhapatnam' },
        { value: 27, label: 'Agra' },
        { value: 28, label: 'Ajmer' },
        { value: 29, label: 'Kanpur' },
        { value: 30, label: 'Mysore' },
        { value: 31, label: 'Srinagar' },
        { value: 32, label: 'Pune' },
    ];
    expops = [{}];
    typehandleChange(event: any) {
        const tv = event.value;
       
        this.setState({ typeoption: tv });
    }
    exphandleChange(event: any) {
        const ev = event.value;
       
        this.setState({ expoption: ev });
    }
    cityhandleChange(event: any) {
        const tv = event.value;

        this.setState({ catoption: tv });
    }

    handleResize() {
        const wd = window.innerWidth;
        const wh = window.innerHeight;
      

        if (wd < 780) {
            this.setState({ windowWidth: '50%' });
        }
        
      
    }
    handleChangecontact = () => {
        const element = <Contact />
        ReactDOM.render(element, document.getElementById('container'));
    };
    setserverurl() {
        debugger;
        const appurl = Configdata.srvurl;
       
        this.setState({ url: appurl });
    }
    componentDidMount() {
        debugger;
        this.setserverurl();
       
        this.Bindhotjobdiv();
      
       
       
        this.Binopeningsdiv();

        this.Bindwalkindiv();
       
        //this.settingclients();
        this.Bindhotjobtopbannerdiv();
     
        this.handleResize();
    }
    settingclients(url: any,sub: any,id: any) {
        const txt = '<a href="#">GOSPETSUP OPENINGS</a>';
        const divtopadd = document.getElementById('topadd') as HTMLElement;
        if (url == null || url == 'undefined' || url == '')
            divtopadd.innerHTML = '<a href="#">' + sub + '</a>';
        else
            divtopadd.innerHTML = sub;
  
    }
    Bindhotjobdiv() {
        debugger;
        var divdata = '';


        fetch(Configdata.srvurl + "/gethotjobleftbaner")
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                const ub = data[0];
                const sb = ub.subj;
                const lnk = ub.pagelink;

                    const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                divdata = divdata + p;

                this.setState({ walk: divdata });
                this.hotjobleftbarfech(divdata);
            }

            }
            );



    }
    
    Bindhotjobtopbannerdiv() {
        debugger;
        var divdata = '';


        fetch(Configdata.srvurl + "/gethotjobtopbaner")
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    const ub = data[0];
                    const sb = ub.subj;
                    const lnk = ub.pagelink;
                    const jid = ub.id;
                    const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                    divdata = divdata + p;



                    this.settingclients(lnk, divdata, jid);
                }

            }
            );



    }
    Bindwalkindiv() {
        debugger;
        var divdata = '';

        const cityid = this.state.catoption;
       
        var cityapi = "/getwalkins";
        if (cityid != 'undefined' && cityid != null)
            cityapi = "/getcitywalkins/" + cityid;

       
        fetch(Configdata.srvurl + cityapi)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    data.forEach((u: any) => {

                        const sb = u.subj;
                        const lnk = u.pagelink;

                        const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                        divdata = divdata + p;


                    })
                    this.setState({ walk: divdata });
                    this.openingsfechadd(divdata);
                }

            }
        );
       
        
        
    }
    
    Binopeningsdiv() {
        debugger; 
        var divdata = '';

        const cityid = this.state.catoption;

        var cityapi = "/getopenins";
        if (cityid != 'undefined' && cityid != null)
            cityapi = "/getcityopenins/" + cityid;
        fetch(Configdata.srvurl + cityapi)
            .then((res) => res.json())
            .then((data) => {
                if (data.length > 0) {
                    data.forEach((u: any) => {
                        const sb = u.subj;
                        const lnk = u.pagelink;

                        const p = '<p className="pwalkink">' + '<a href=' + lnk + '  ' + 'target="_blank"' + '>' + sb + '</a>' + '</p>'

                        divdata = divdata + p;


                    })

                    this.openingsfechadd(divdata);
                }

            }
            );
    }
   
    Checkphone() {
        //const localurl = this.state.url;
     
    }
    insertresume() {
       
       
    }
    handleClick() {
      //  this.Checkphone();
        
                  
    } 
    hotjobleftbarfech(d: any) {
     
        const divjobhotleftbbar = document.getElementById('jobhomeleftbanner') as HTMLElement;
        divjobhotleftbbar.innerHTML = d;
    }
    openingsfechadd(d: any) {

        const divjobwalkinss = document.getElementById('homejobopenings') as HTMLElement;
        divjobwalkinss.innerHTML = d;
    }
 render() {
for (let i = 1; i <= 40; i++) {
  var opt = {value: i, label: i};
this.expops.push(opt);
}
    
     return (

<form>
             <div className="maindiv">
                 <div className="top-dibv-scr">
                     <div className="searchdiv"> 
                        
                         <span className="jobhome-button">
                             <Select onChange={e => this.cityhandleChange(e)} id="ctype" options={this.cities} className="textbox-name-job" />

                         </span>
                         <span className="jobhome-button">
                             <button style={{ height: '30px' }} onClick={this.Bindwalkindiv.bind(this)}>Walkin</button>
                         </span>
                         <span className="jobhome-button">
                             <button style={{ height: '30px' }} onClick={this.Binopeningsdiv.bind(this)}>Opening</button>
                         </span>
                       

                     </div>
                 </div>
              <div className="top-dibv-add">
              
                     <div id="topadd" onClick={this.handleChangecontact} className="top-add">
                     
                  </div>
                 
                 
                 </div>
                 <div className="home-table">
                     <table style={{ width: '100%',height:'25em',border: 'none' }} className="home-table">
                         <tr>
                            
                           
                             <td style={{ width: '100%', border: 'none' }}>
                                 <div  className="middlediv">
                                     <div className="containerlinkdiv" id="homejobopenings" >
                                     </div>
                                 </div>
                             </td>
                         </tr>

                     </table>
                 </div>
              
                 <div className="bottom-dibv-jobadd">

                     {/*<div id="jobhomeleftbanner" onClick={this.handleChangecontact} className="top-add">*/}

                     {/*</div>*/}
                     <div id="jobhomeleftbanner" className="top-add">

                     </div>
                 </div>
              </div>
            
    

    


</form>

      );
}
}
//export default Home;
